import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedHTMLMessage, useIntl, navigate } from 'gatsby-plugin-intl';

import Button from '../Button';

import style from './ContactForm.module.css';

const encode = (data) =>
  Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');

const ContactForm = () => {
  const { register, handleSubmit, errors } = useForm();

  const intl = useIntl();

  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);

  const onSubmit = (values) => {
    setIsSubmitButtonClicked(true);
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': 'contact',
        ...values,
      }),
    })
      .then(() => navigate('/thanks'))
      .catch(() => navigate('/error'));
  };

  return (
    <div className={style.container}>
      <h1>
        <FormattedHTMLMessage id={'main.contactForm.title'} />
      </h1>
      <h4>
        <FormattedHTMLMessage id={'main.contactForm.subTitle'} />
      </h4>
      <form
        name="contact"
        method="post"
        className={style.form}
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit(onSubmit)}
      >
        <p>
          <input
            className={style.hiddenInput}
            type="hidden"
            aria-label="form-name"
            name="form-name"
            value="contact"
          />
        </p>
        <p>
          <input
            className={style.hiddenInput}
            name="bot-field"
            aria-label="hidden"
            ref={register}
          />
        </p>
        <p className={style.field}>
          <input
            name="name"
            className={style.input}
            aria-label="name"
            placeholder={intl.formatMessage({
              id: 'main.contactForm.form.name.placeholder',
            })}
            ref={register({
              required: intl.formatMessage({
                id: 'main.contactForm.form.name.errorMessage.empty',
              }),
              minLength: {
                value: 3,
                message: intl.formatMessage({
                  id: 'main.contactForm.form.name.errorMessage.minLength',
                }),
              },
            })}
          />
          {errors.name && (
            <span className={style.error}>{errors.name.message}</span>
          )}
        </p>
        <p className={style.field}>
          <input
            name="email"
            aria-label="email"
            className={style.input}
            placeholder={intl.formatMessage({
              id: 'main.contactForm.form.email.placeholder',
            })}
            ref={register({
              required: intl.formatMessage({
                id: 'main.contactForm.form.email.errorMessage.empty',
              }),
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: intl.formatMessage({
                  id: 'main.contactForm.form.email.errorMessage.wrongEmail',
                }),
              },
            })}
          />
          {errors.email && (
            <span className={style.error}>{errors.email.message}</span>
          )}
        </p>
        <p className={style.field}>
          <textarea
            name="message"
            aria-label="message"
            className={style.textarea}
            placeholder={intl.formatMessage({
              id: 'main.contactForm.form.message.placeholder',
            })}
            ref={register({
              required: intl.formatMessage({
                id: 'main.contactForm.form.message.errorMessage.empty',
              }),
              minLength: {
                value: 10,
                message: intl.formatMessage({
                  id: 'main.contactForm.form.message.errorMessage.minLength',
                }),
              },
            })}
          />
          {errors.message && (
            <span className={style.error}>{errors.message.message}</span>
          )}
        </p>
        <Button
          classNames={style.button}
          titleKey="main.contactForm.form.button"
          type="submit"
          disabled={isSubmitButtonClicked}
        />
      </form>
    </div>
  );
};

export default ContactForm;
