import React from 'react';
import PropTypes from 'prop-types';
import { Link, FormattedMessage } from 'gatsby-plugin-intl';
import classnames from 'classnames';

import style from './Button.module.css';

const Button = ({
  linkTo,
  classNames,
  titleKey,
  onClick,
  type,
  disabled = false,
}) => {
  let Component = 'button';
  const params = {
    className: classnames(style.container, classNames, {
      [style.disabled]: disabled,
    }),
    ...(type && { type }),
  };

  if (linkTo) {
    Component = Link;
    params.to = linkTo;
  } else if (onClick) {
    params.onClick = onClick;
    params.disabled = disabled;
  }

  return (
    <Component {...params}>
      <FormattedMessage id={titleKey} />
    </Component>
  );
};

Button.propTypes = {
  linkTo: PropTypes.string,
  classNames: PropTypes.string,
  titleKey: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Button;
