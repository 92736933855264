import React from 'react';

import Layout from '../components/Layout';
import ContactForm from '../components/ContactForm';

const ContactPage = () => (
  <Layout>
    <ContactForm />
  </Layout>
);

export default ContactPage;
